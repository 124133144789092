<template>
  <div class="modal topup-modal">
    <div class="overlay" @click="$parent.closeTopup"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img
              class="close"
              @click="$parent.closeTopup"
              src="./../images/close.svg"
            />
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="cart-top">
                    <div class="desc">
                      <b>ADDING FUNDS TO BALANCE</b>
                    </div>
                  </div>
                  <div class="topup-form">
                    <div class="desc">PAYMENT METHODS:</div>
                    <div class="payment-methods">
                      <button
                        v-for="method in $parent.paymentMethods"
                        :key="method.code"
                        @click="setPaymentType(method.code)"
                        :class="[
                          'payment-button',
                          { selected: paymentType === method.code },
                        ]"
                      >
                        <img
                          :src="method.image"
                          :alt="method.title"
                          v-if="method.image != ''"
                        />
                        <span class="method-span" v-if="method.image == ''">{{
                          method.title
                        }}</span>
                      </button>
                    </div>
                    <label class="input-container">
                      <input type="text" placeholder="Name" v-model="name" />
                    </label>
                    <label class="input-container">
                      <input
                        type="text"
                        placeholder="Surname"
                        v-model="surname"
                      />
                    </label>
                    <label class="input-container">
                      <input type="tel" placeholder="Phone" v-model="phone" />
                    </label>
                    <label class="input-container">
                      <input type="email" placeholder="Email" v-model="email" />
                    </label>
                    <label class="input-container">
                      <select v-model="selectedCountry">
                        <option value="" disabled selected>Countries</option>
                        <option
                          :value="item"
                          v-for="item in countries"
                          :key="item.id"
                        >
                          {{ item.title }}
                        </option>
                      </select>
                    </label>
                    <label class="input-container">
                      <input
                        type="text"
                        placeholder="Steam id"
                        v-model="steamId"
                      />
                    </label>
                    <label class="input-container">
                      <input type="text" placeholder="City" v-model="city" />
                    </label>
                    <label class="input-container">
                      <input
                        type="text"
                        placeholder="Address"
                        v-model="address"
                      />
                    </label>
                    <label class="input-container">
                      <input
                        type="text"
                        placeholder="Post Code"
                        v-model="postCode"
                      />
                    </label>
                    <div class="input-container">
                      <input
                        type="text"
                        @input="allowOnlyNumbers"
                        placeholder="Enter amount"
                        v-model="amount"
                      />
                      <div class="desc">
                        {{ $parent.currency }}
                      </div>
                    </div>

                    <div class="chekbox-container">
                      <label class="chekbox-label">
                        <div class="chekbox-label-wrapper">
                          <input type="checkbox" name="terms" v-model="terms" />
                          <div class="checkbox"></div>
                          <span class="title">I agree with </span>
                          <a @click="$parent.goToPage('privacy')" class="title">
                            privacy policy</a
                          >
                          <span class="title"> and </span>
                          <a @click="$parent.goToPage('terms')" class="title">
                            terms and conditions</a
                          >
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="cart-bottom">
                  <div class="desc">
                    You will get:
                    <b>{{ $parent.currency }} {{ amount }}</b>
                  </div>
                  <button
                    @click="send"
                    :class="[
                      'button red',
                      { disabled: !requiredRegisterFieldsAreFilled },
                    ]"
                  >
                    PAY
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopUp",
  props: [],
  data: function () {
    return {
      amount: 5,
      name: "",
      surname: "",
      email: "",
      phone: "",
      country: "",
      selectedCountry: "",
      countryId: "",
      city: "",
      address: "",
      postCode: "",
      countryOptions: [],
      countryOptionsId: [],
      paymentType: "",
      steamId: "",
      countries: [],
      terms: "",
    };
  },
  mounted() {
    this.getCounties();
    this.$http
      .get(process.env.VUE_APP_API + "user/profile")
      .then((res) => {
        this.phone = res.data.phone;
        this.name = res.data.name;
        this.email = res.data.email;
        this.surname = res.data.surname;
        this.countryId = res.data.country;
        this.city = res.data.city;
        this.address = res.data.address;
        this.postCode = res.data.zip;
        this.steamId = res.data.steamId;
        if (this.countries && this.countries.length && this.countryId) {
          this.setCountry();
        }
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignIn();
        }
      });

    // Set the first payment method as selected by default
    if (this.$parent.paymentMethods && this.$parent.paymentMethods.length) {
      this.paymentType = this.$parent.paymentMethods[0].code;
    }
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name &&
        this.surname &&
        this.phone &&
        this.selectedCountry &&
        this.email &&
        this.city &&
        this.address &&
        this.amount &&
        this.steamId &&
        this.postCode &&
        this.paymentType &&
        this.terms
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    setPaymentType(type) {
      this.paymentType = type;
    },
    setCountry() {
      let mapped = this.countries.map(({ id, title }) => ({
        id,
        title,
      }));
      this.countries = mapped;
      this.selectedCountry = this.countries.find(
        (item) => item.id == this.countryId
      );

      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == this.selectedCountry.name) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
    },
    getCounties() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function (item) {
            return item["title"];
          });
          let newCountriesId = arr.map(function (item) {
            return item["id"];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
          this.countries = arr;
          let mapped = this.countries.map(({ id, title }) => ({
            id,
            title,
          }));
          this.countries = mapped;

          if (this.countries && this.countries.length && this.countryId) {
            this.setCountry();
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignIn();
          }
        });
    },
    allowOnlyNumbers() {
      this.amount = this.amount.replace(/[^0-9.,]/g, "");

      // Split the input into integer and decimal parts
      const parts = this.amount.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.amount =
        decimalPart !== undefined
          ? `${integerPart}.${decimalPart}`
          : integerPart;
    },
    send() {
      let data = {
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phone,
        amount: this.amount,
        country: this.selectedCountry.id,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
        steamId: this.steamId,
        paymentType: this.paymentType,
        currency: this.$parent.currencyCode,
      };
      this.$http
        .post(process.env.VUE_APP_API + "deposit/checkout", data)
        .then((res) => {
          if (res.data.status == "OK") {
            window.location = res.data.redirect_url;
          } else {
            this.$emit("setError", res.data.message);
          }
        })
        .catch((res) => {
          this.$emit("setError", res.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
.payment-methods {
  display: flex;
  margin-top: 15px;
  align-items: center;
  gap: 10px;
}
.method-span {
  color: #000;
  font-size: 12px;
}

.payment-button {
  background: none;
  color: #ffdd32;
  background: #ffdd32;
  border-radius: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 50px;
  height: 50px;
  background-color: white;
}

.payment-button img {
  width: 97%;
  height: 97%;
}

.payment-button.selected {
  background: #ffdd32;
  border-radius: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
